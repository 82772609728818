import React from "react";

const ContentLoadingIndicator = ({
    height,
    paddingLeft,
    justifyContent,
}) => (
    <div
        className="is-flex is-align-items-center"
        style={{
            height: `${height || '100%'}`,
            paddingLeft: `${paddingLeft || '15%'}`,
            justifyContent: `${justifyContent || 'start'}`,
        }}
    >
        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <p className="is-sr-only">Loading...</p>
    </div>
);

export const PageContentLoadingIndicator = () => (
    <ContentLoadingIndicator
        paddingLeft="0"
        height="50%"
        justifyContent="center"
    />
);

export default ContentLoadingIndicator;