import React from "react";
import { navigate } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";

export const VERIFY_EMAIL_PATH = '/profile/trial/verify-email';
export const INTRODUCTION_PATH = '/profile/trial/introduction';
export const CREATE_PATH = '/profile/trial/create';
export const TRIAL_PATH = '/profile/trial';
export const PURCHASE_PATH = '/profile/trial/purchase/';
export const REDIRECT_TO_TENANT_PATH = '/profile/trial/redirect-to-tenant';

export const TENANT_PATH = '/profile/tenant';
export const CONTRACT_AND_INVOICES_PATH = '/profile/contracts-and-invoices';
export const MANAGE_SUBSCRIPTION_PATH = '/profile/manage-subscription';
export const PURCHASE_SUCCESSFUL_PATH = '/profile/purchase-successful';

const PUBLIC_TRIAL_PATH = '/trial';

const useTrialAuthClaimCheck = (path) => {
    const [loading, setLoading] = React.useState(true);
    const { isLoading: authLoading, user } = useAuth0();

    React.useEffect(() => {
        if (authLoading) {
            return;
        }

        if (!user) {
            navigate(PUBLIC_TRIAL_PATH);
            return;
        }

        const trialTenant = user['https://frendsapp.com/trial-tenant-name']; // custom claim added when trial tenant is created, if exists user must be admin
        const allowedTenants = user['https://frendsapp.com/allowed-tenants']; // custom claim added when user is invited to tenant

        if (!trialTenant && allowedTenants?.length) {
            navigate(REDIRECT_TO_TENANT_PATH);
            return;
        }

        const emailVerified = user['email_verified'];
        const tenant = user['https://frendsapp.com/tenant-name']; // custom claim added when tenant is subscription is purchased, if exists user must be admin

        if (emailVerified && tenant) {
            navigate(TENANT_PATH);
            return;
        }

        if (!emailVerified && path !== VERIFY_EMAIL_PATH) {
            navigate(VERIFY_EMAIL_PATH);
            return;
        }

        if (emailVerified && !trialTenant && !(path === INTRODUCTION_PATH || path === CREATE_PATH)) {
            navigate(INTRODUCTION_PATH);
            return;
        }

        if (emailVerified && trialTenant && path === PURCHASE_PATH) {
            setLoading(false);
            return;
        }

        if (emailVerified && trialTenant && path !== TRIAL_PATH) {
            navigate(TRIAL_PATH);
            return;
        }

        setLoading(false);
    }, [authLoading, user]);

    return loading;
}

export const useAuthClaimCheck = (path) => {
    const [loading, setLoading] = React.useState(true);
    const { isLoading: authLoading, user } = useAuth0();

    React.useEffect(() => {
        if (authLoading) {
            return;
        }

        if (!user) {
            navigate(PUBLIC_TRIAL_PATH);
            return;
        }

        const trialTenant = user['https://frendsapp.com/trial-tenant-name']; // custom claim added when trial tenant is created, if exists user must be admin
        const tenant = user['https://frendsapp.com/tenant-name']; // custom claim added when tenant is subscription is purchased, if exists user must be admin
        const allowedTenants = user['https://frendsapp.com/allowed-tenants']; // custom claim added when user is invited to tenant

        if (!trialTenant && !tenant && allowedTenants?.length) {
            navigate(REDIRECT_TO_TENANT_PATH);
            return;
        }

        if (!user['email_verified'] || !tenant) {
            navigate(TRIAL_PATH);
            return;
        }

        if (path === CONTRACT_AND_INVOICES_PATH) { //TODO: remove block
            navigate(TENANT_PATH);
            return;
        }

        setLoading(false);
    }, [authLoading, user]);

    return loading;
}

export default useTrialAuthClaimCheck;