import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "../../../assets/scss/main.scss";
import "../../../assets/fontawesome/css/all.min.css";

import { REDIRECT_TO_TENANT_PATH } from "../../../components/profile/shared/AuthClaimCheckHook";
import ContentLoadingIndicatorContainer from "../../../components/profile/shared/ContentLoadingIndicatorContainer";

export default () => {
    const { loginWithRedirect } = useAuth0();

    React.useEffect(() => {
        loginWithRedirect({
            authorizationParams: {
                redirect_uri: `${window.location.origin}${REDIRECT_TO_TENANT_PATH}`
            }
        });
    }, []);

    return <ContentLoadingIndicatorContainer />;
};