import React from "react";

import ContentLoadingIndicator from "./ContentLoadingIndicator";

const ContentLoadingIndicatorContainer = () => (
    <main style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <ContentLoadingIndicator paddingLeft="0" />
    </main>
);

export default ContentLoadingIndicatorContainer;